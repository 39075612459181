import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import Notification, {
  NotificationData,
} from "../../../../../components/Notification";
import CancellationForm, { Agent } from "../CancellationForm";
import { ActivityReservationType, DialogName } from "../types";
import { GET_BOOKING } from "../..";

export default function CancelWithRefundDialog(props: {
  bookingId: string;
  cancelFee?: number | null;
  open: boolean;
  setOpenDialog: (name: DialogName) => void;
  closeDialog: () => void;
  reservation: ActivityReservationType;
}) {
  const [cancelledBy, setCancelledBy] = useState<Agent | unknown>("customer");
  const [cancellationReason, setCancellationReason] = useState<string>("");
  const [cancellationFeeNotes, setCancellationFeeNotes] = useState<string>("");
  const [cancellationFee, setCancellationFee] = useState<
    number | null | undefined
  >(props.cancelFee);
  const [cancelBookingMutation] = useMutation(CANCEL_BOOKING);
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );

  return (
    <>
      <Dialog open={props.open} onClose={props.closeDialog}>
        <DialogTitle>Cancel Booking With Refund</DialogTitle>
        <DialogContent>
          Are you sure you want to cancel the booking with a refund?
          <br />
          <br />
          This action will: <br />
          <ul>
            <li>Refund the payment</li>
            <li>Change booking payment status to REFUNDED</li>
            <li>Change booking status to CANCELLED</li>
            <li>
              Send email to customer that the booking has been cancelled with a
              refund
              <br />
              (if reservation datetime has not already passed)
            </li>
            <li>Change product order status to 'FAILED'</li>
          </ul>
          <CancellationForm
            cancelledBy={cancelledBy}
            setCancelledBy={setCancelledBy}
            cancellationReason={cancellationReason}
            setCancellationReason={setCancellationReason}
            cancellationFeeNotes={cancellationFeeNotes}
            setCancellationFeeNotes={setCancellationFeeNotes}
            cancellationFee={cancellationFee}
            setCancellationFee={setCancellationFee}
            disableCancelFeeInput={Boolean(props.cancelFee)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.closeDialog}>
            No
          </Button>
          <Button color="primary" onClick={cancelBooking}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function cancelBooking() {
    if (!cancelledBy || !cancellationReason) {
      setNotification({
        message: `Please set who cancelled and the cancellation reason`,
        severity: "error",
      });
    } else {
      cancelBookingMutation({
        variables: {
          input: {
            bookingId: props.bookingId,
            cancelledBy: cancelledBy,
            cancellationReason: cancellationReason,
            cancellationFeeNotes: cancellationFeeNotes,
            cancellationFee: cancellationFee,
            shouldGetRefund: true,
          },
        },
        refetchQueries: [GET_BOOKING],
      })
        .then(() => {
          setCancellationReason("");
          setNotification({
            message: `Booking ${props.bookingId} was successfully cancelled with a refund`,
            severity: "success",
          });
          if (
            // works while we only have 1 activity/booking
            props.reservation.status === "NOT_YET_RESERVED" ||
            props.reservation.status === "RESERVED"
          )
            props.setOpenDialog("CANCEL_RESERVATION");
          else props.closeDialog();
        })
        .catch((reason) => {
          setNotification({
            message: `Cancelling booking with refund failed! ${reason}`,
            severity: "error",
          });
        });
    }
  }
}

const CANCEL_BOOKING = gql`
  mutation cancelBooking($input: CancelBookingInput!) {
    cancelBooking(input: $input) {
      success
    }
  }
`;
