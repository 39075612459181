import Grid from "@mui/material/Grid";

import TextField from "../../../../../components/TextField";
import { Activity, UnitType } from "../../../../../types";

interface ItemProps {
  data: ItemType;
  setQuantity: (quantity: number) => void;
  readOnly?: boolean;
  activity?: Activity | null;
}

function Item({ data, setQuantity, readOnly, activity }: ItemProps) {
  const priceType = getPriceType(data.priceTypeId, activity?.priceTypes);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            readOnly={true}
            label={"Price Type"}
            value={priceType?.name ?? data.priceType}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            readOnly={true}
            label={"Unit type"}
            value={
              priceType?.unitType
                ? getUnitType(priceType?.unitType)
                : data.unitType
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            type="text"
            readOnly={readOnly ?? true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            label={"Quantity"}
            value={data.quantity}
            onChange={(e: any) => {
              const value = Number(e.target.value);
              if (!isNaN(value)) setQuantity(value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            readOnly={true}
            label={"Amount"}
            value={priceType?.amountInMinorUnits ?? data.amount}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Item;

export type ItemType = {
  id?: number;
  priceType: string;
  priceTypeId?: string;
  unitType: string;
  quantity: number;
  amount: number;
  contractedPrice: number;
};

export function getPriceType(
  id: string | undefined,
  priceTypes:
    | {
        id: string;
        unitType: UnitType;
        name: string;
        amount: number;
        amountInMinorUnits?: number;
        contractedPrice: number;
        currencyCode: string;
        minAttendees: number;
        maxAttendees: number;
      }[]
    | undefined
) {
  if (!id || !priceTypes) return;
  const priceType = priceTypes.find((p) => p.id === id) ?? null;
  return priceType;
}

function getUnitType(type: string) {
  return type === "PERSON" ? "名" : "セット";
}
