import React from "react";
import { BookingEditHistoryType } from ".";
import { TextField, Divider } from "@mui/material";

type Props = {
  edit: BookingEditHistoryType;
};

const BasicCard = ({ edit }: Props) => {
  const oldValue = JSON.parse(edit.oldValue);
  const newValue = JSON.parse(edit.newValue);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <p style={{ fontSize: 22, margin: 0 }}>Original</p>
      <div
        style={{
          backgroundColor: "#e8e8e8",
          padding: 10,
          borderRadius: 8,
          marginBottom: 10,
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        {Object.entries(oldValue).map((item, idx) => {
          return (
            <TextField
              key={idx}
              value={item[1]}
              label={
                Object.entries(newValue).length > 1 ? item[0] : edit.fieldName
              }
              multiline
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          );
        })}
      </div>
      <Divider />
      <p style={{ fontSize: 22, margin: 0 }}>Changes</p>
      {Object.entries(newValue).map((item, idx) => {
        return (
          <TextField
            key={idx}
            value={item[1]}
            label={
              Object.entries(newValue).length > 1 ? item[0] : edit.fieldName
            }
            multiline
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        );
      })}
    </div>
  );
};

export default BasicCard;
