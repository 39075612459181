import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import BasicInfoForm from "../../components/BasicInfoForm";

export default function BasicInfo() {
  const { id } = useParams<{ id: string }>();

  const [editPlan] = useMutation(EDIT_PLAN_BASIC_INFO_MUTATION);

  const { data, loading, error } = useQuery(PLAN_BASIC_INFO_QUERY, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  if (loading) return <>loading...</>;
  if (error) return <>{error.message}</>;

  return (
    <BasicInfoForm
      plan={data.plan}
      onSubmit={async ({ score, ...plan }) => {
        await editPlan({ variables: { input: plan } });
      }}
    />
  );
}

const PLAN_BASIC_INFO_QUERY = gql`
  query PlanBasicInfoQuery($id: ID!) {
    plan(id: $id) {
      id
      name
      timezone
      locationId
      areaName
      description
      remarks
      cancellationPolicyDescription
      paymentMethodDescription
      subtitle
      score
      manualScore
      cancelFee
      conciergeComment
      conciergeId
      videoUrl
      exclusive
      onSitePayment
      amazonPay
      stripe
      basePrice
      basePriceName
      bookingType
      redirectUrl
      inclusions {
        category
        items {
          id
          title
          description
          iconName
        }
      }
      tags {
        id
        name
        tagCategory {
          id
          name
        }
      }
      planTemplates {
        id
      }
      mainVenue {
        name
        ebicaStoreId
      }
    }
  }
`;

const EDIT_PLAN_BASIC_INFO_MUTATION = gql`
  mutation EditPlanBasicInfoMutation($input: EditPlanBasicInfoInput!) {
    editPlanBasicInfo(input: $input) {
      plan {
        id
      }
    }
  }
`;
