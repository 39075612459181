import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slider from "@mui/material/Slider";
import moment from "moment-timezone";

import Notification, {
  NotificationData,
} from "../../../../components/Notification";
import { ActivityReservationType } from "../ActionButtons/types";

const VenuePaymentRateMarks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export default function CancelReservationButton(
  props: CancelReservationButtonProps
) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );

  const reservationDateTime = moment(props.reservation.datetime)
    .tz("Asia/Tokyo")
    .format();
  const currentDateTime = moment().tz("Asia/Tokyo").format();

  // If reservation datetime already passed, not allowed to cancel anymore
  if (reservationDateTime < currentDateTime) return null;

  return (
    <>
      <Button
        style={{ backgroundColor: "#9c27b0", color: "#ffffff" }}
        variant="contained"
        startIcon={<ClearIcon />}
        onClick={() => setDialogOpen(true)}
      >
        Cancel
      </Button>
      <CancelDialog />
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function closeDialog() {
    setDialogOpen(false);
  }

  function CancelDialog() {
    const [cancelVenueReservation] = useMutation(CANCEL_VENUE_RESERVATION);
    const [venuePaymentRate, setVenuePaymentRate] = useState(0);

    const reservationItems = props.reservation.items;
    const commissionRate =
      props.reservation?.activity?.contract?.commissionRate;
    const rateForVenue = (100 - commissionRate) / 100;

    const expectedForVenue =
      reservationItems.reduce(
        (total, item) => total + item.contractedPrice * item.quantity,
        0
      ) * rateForVenue;

    const actualAmountForVenue = (expectedForVenue * venuePaymentRate) / 100;

    return (
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Cancel Venue Reservation</DialogTitle>
        <DialogContent>
          Are you sure you want to cancel the reservation?
          <br />
          <br />
          This action will:
          <ul>
            <li>
              For instant bookings, cancel reservation at external booking
              management services (like Ebica)
            </li>
            <li>Change reservation status to CANCELLED</li>
            <li>Send reservation cancelled email to venue</li>
            <li>
              <b>Please update the booking manually</b>
            </li>
          </ul>
          <p>
            Do we still need to pay the venue?
            <br />
            Contract: {props.reservation.cancellationPolicyDescription || ""}
            <Slider
              defaultValue={venuePaymentRate}
              aria-labelledby="discrete-slider-small-steps"
              step={5}
              marks={VenuePaymentRateMarks}
              min={0}
              max={100}
              valueLabelDisplay="on"
              onChange={(_e: unknown, newValue) =>
                setVenuePaymentRate(newValue as number)
              }
            />
            <br />
            {`${actualAmountForVenue}JPY of ${expectedForVenue}JPY will be paid to the venue`}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button color="primary" onClick={cancelReservation}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );

    function cancelReservation() {
      cancelVenueReservation({
        variables: {
          input: {
            activityReservationId: props.reservation.id,
            venuePaymentRate,
          },
        },
        refetchQueries: [
          "VenueReservationsListQuery",
          "BookingActivityReservationsQuery",
          "BookingQuery",
        ],
      })
        .then(() => {
          setNotification({
            message: `Venue reservation was successfully canceled`,
            severity: "success",
          });
        })
        .catch((reason) => {
          setNotification({
            message: `Cancelling venue reservation failed! ${reason}`,
            severity: "error",
          });
        });

      closeDialog();
    }
  }
}

const CANCEL_VENUE_RESERVATION = gql`
  mutation cancelVenueReservation($input: CancelVenueReservationInput!) {
    cancelVenueReservation(input: $input) {
      success
    }
  }
`;

interface CancelReservationButtonProps {
  reservation: ActivityReservationType;
}
