import { useState } from "react";
import Button from "@mui/material/Button";

import { ActivityReservationType, DialogName } from "./types";
import { BookingStatusColor } from "../../helpers";
import CancelDialog from "./Dialogs/Cancel";
import CancelWithoutRefundDialog from "./Dialogs/CancelWithoutRefund";
import CancelWithRefundDialog from "./Dialogs/CancelWithRefund";
import CancelReservationDialog from "./Dialogs/CancelReservation";
import { PaymentStatus, Booking } from "../../../../types";

function CancelBookingButton(props: {
  reservations: ActivityReservationType[];
  booking: Pick<
    Booking,
    | "id"
    | "status"
    | "paymentStatus"
    | "paymentIntent"
    | "planSnapshot"
    | "paymentAmount"
  >;
}) {
  const { id, paymentStatus, paymentIntent } = props.booking;
  const [openDialog, setOpenDialog] = useState<null | DialogName>(null);

  return (
    <>
      <Button
        style={{
          backgroundColor: BookingStatusColor["CANCELLED"],
          color: "white",
        }}
        variant="contained"
        onClick={() => {
          if (props.booking.status === "CANCELLED")
            setOpenDialog("CANCEL_RESERVATION");
          else if (
            paymentStatus === PaymentStatus.REFUNDED ||
            paymentIntent?.method === "ON_SITE_PAYMENT"
          )
            setOpenDialog("CANCEL_WITHOUT_REFUND");
          else setOpenDialog("CANCEL");
        }}
      >
        Cancel Booking
      </Button>
      <CancelDialog
        open={openDialog === "CANCEL"}
        setOpenDialog={setOpenDialog}
        closeDialog={closeDialog}
      />
      <CancelReservationDialog
        reservation={props.reservations[0]}
        open={openDialog === "CANCEL_RESERVATION"}
        closeDialog={closeDialog}
        paymentMethod={paymentIntent?.method}
        paymentStatus={paymentStatus}
      />
      <CancelWithoutRefundDialog
        bookingId={id}
        cancelFee={getCancelFee(
          props.booking.planSnapshot.cancelFee,
          Number(props.booking.paymentAmount)
        )}
        open={openDialog === "CANCEL_WITHOUT_REFUND"}
        closeDialog={closeDialog}
        setOpenDialog={setOpenDialog}
        paymentMethod={paymentIntent?.method}
        reservation={props.reservations[0]}
      />
      <CancelWithRefundDialog
        bookingId={id}
        cancelFee={getCancelFee(
          props.booking.planSnapshot.cancelFee,
          Number(props.booking.paymentAmount)
        )}
        open={openDialog === "CANCEL_WITH_REFUND"}
        closeDialog={closeDialog}
        setOpenDialog={setOpenDialog}
        reservation={props.reservations[0]}
      />
    </>
  );

  function closeDialog() {
    setOpenDialog(null);
  }
}

export default CancelBookingButton;

export function getCancelFee(cancelFee: number | undefined, amount: number) {
  if (!cancelFee) return null;
  return (cancelFee * amount) / 100;
}
