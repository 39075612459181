import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import { Location } from "../../../../types";
import EditLocationForm from "./form";

export default function BasicInfo() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery(GET_LOCATION_QUERY, {
    variables: { id: id },
  });
  const { error: errorLocations, data: dataLocations } = useQuery(
    GET_LOCATIONS
  );
  if (loading) return <CircularProgress />;
  if (error) return <>{error.message}</>;

  const location: Location = data ? data.location : null;

  if (errorLocations) return <>Error: {errorLocations.message}</>;
  const locations = dataLocations ? dataLocations.locations.records : [];

  return <EditLocationForm location={location} locations={locations} />;
}

const GET_LOCATION_QUERY = gql`
  query GetLocationQuery($id: ID!) {
    location(id: $id) {
      id
      createdAt
      type
      nameEn
      nameJa
      slug
      pageTitle
      pageDescription
      thumbnailImageUrl
      imageUrl
      videoUrl
      isHomeContent
      homePriority
      includeArea {
        id
        nameEn
        nameJa
      }

      parent {
        id
        nameEn
        nameJa
      }

      myparent {
        id
        nameEn
        nameJa
      }
    }
  }
`;

const GET_LOCATIONS = gql`
  query LocationsQuery(
    $offset: Int
    $limit: Int
    $type: LocationType
    $search: String
  ) {
    locations(offset: $offset, limit: $limit, type: $type, search: $search) {
      totalRecords
      records {
        id
        type
        nameEn
        nameJa

        parent {
          type
          nameEn
          nameJa

          parent {
            type
            nameEn
            nameJa
          }
        }
      }
    }
  }
`;
