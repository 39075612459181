import React, { useState, useEffect } from "react";
import { Grid, TextField, IconButton, Select, MenuItem } from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useNotifications } from "../../../../components/Notification";
import PageSection from "../../../../components/PageSection";
import Button2 from "../../../../components/Button2";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MediaUploader, { MediaType } from "../../../../components/MediaUploader";

type PaidItem = {
  id?: string;
  title?: string;
  body: string;
  imageUrl: null | string;
  name: string;
  price: number;
  status: number;
};

type PlanPaidItemInput = {
  planId: string;
  name: string;
  price: number;
  description: string;
  planQuestionId: string;
  status: number;
  imageUrl: string;
};

const initialPaidItem: PaidItem = {
  title: "",
  body: "",
  imageUrl: null,
  name: "",
  price: 0,
  status: 1,
};

const GET_PLAN_PAID_ITEMS = gql`
  query getPlanPaidItems($planId: ID!) {
    getPlanPaidItems(planId: $planId) {
      id
      name
      price
      description
      planQuestionId
      status
      imageUrl
    }
  }
`;

const CREATE_PLAN_PAID_ITEM = gql`
  mutation createPlanPaidItem($input: PlanPaidItemInput!) {
    createPlanPaidItem(input: $input) {
      id
    }
  }
`;

const UPDATE_PLAN_PAID_ITEM = gql`
  mutation UpdatePlanPaidItem($id: ID!, $input: PlanPaidItemInput!) {
    updatePlanPaidItem(id: $id, input: $input) {
      id
      planId
      name
      price
      description
      planQuestionId
      status
      imageUrl
    }
  }
`;

const DELETE_PLAN_PAID_ITEM = gql`
  mutation DeletePlanPaidItem($id: ID!) {
    deletePlanPaidItem(id: $id) {
      success
    }
  }
`;

export default function PaidOptionForm() {
  const { id: planId } = useParams<{ id: string }>();
  const { showNotification } = useNotifications();
  const [paidItems, setPaidItems] = useState<PaidItem[]>([]);
  const [errors, setErrors] = useState<boolean[]>([]); // To track field errors
  const [createPlanPaidItem] = useMutation(CREATE_PLAN_PAID_ITEM);
  const [updatePlanPaidItem] = useMutation(UPDATE_PLAN_PAID_ITEM);
  const [deletePlanPaidItem] = useMutation(DELETE_PLAN_PAID_ITEM);

  const { loading, error, data, refetch } = useQuery(GET_PLAN_PAID_ITEMS, {
    variables: { planId },
  });

  useEffect(() => {
    if (data && data.getPlanPaidItems) {
      const fetchedPaidItems = data.getPlanPaidItems.map((item: any) => ({
        id: item.id,
        name: item.name,
        price: item.price,
        body: item.description,
        imageUrl: item.imageUrl,
        status: item.status,
      }));
      setPaidItems(fetchedPaidItems);
      setErrors(new Array(fetchedPaidItems.length).fill(false)); // Initialize error state
    }
  }, [data]);

  const handleInputChange = (
    index: number,
    field: keyof PaidItem,
    value: any
  ) => {
    setPaidItems((prevPaidItems: any) =>
      prevPaidItems.map((paidItem: PaidItem, idx: number) =>
        idx === index ? { ...paidItem, [field]: value } : paidItem
      )
    );
  };

  const handleImageUpload = (index: number, file: string | null) => {
    handleInputChange(index, "imageUrl", file);
  };

  const handleAddMore = () => {
    setPaidItems([...paidItems, { ...initialPaidItem }]);
    setErrors([...errors, false]); // Add a new error flag
  };

  const validatePaidItems = (): boolean => {
    const newErrors = paidItems.map((item) => {
      return (
        !item.name || item.price <= 0 || !item.body || item.status === undefined
      );
    });
    setErrors(newErrors);
    return newErrors.every((error) => !error); // If no errors, return true
  };

  const handleDeletePaidItem = async (index: number) => {
    const item = paidItems[index];

    if (item.id) {
      try {
        const response = await deletePlanPaidItem({
          variables: { id: item.id },
        });

        if (response.data.deletePlanPaidItem.success) {
          setPaidItems((prevPaidItems) =>
            prevPaidItems.filter((_, idx) => idx !== index)
          );
          setErrors((prevErrors) =>
            prevErrors.filter((_, idx) => idx !== index)
          ); // Adjust error array
          showNotification({
            message: "Paid Item deleted successfully",
            severity: "success",
          });
          refetch();
        }
      } catch (error: any) {
        showNotification({
          message: error.message,
          severity: "error",
        });
      }
    } else {
      setPaidItems((prevPaidItems) =>
        prevPaidItems.filter((_, idx) => idx !== index)
      );
      setErrors((prevErrors) => prevErrors.filter((_, idx) => idx !== index));
    }
  };

  const handleSave = async () => {
    if (!validatePaidItems()) {
      showNotification({
        message: "Please fill in all required fields.",
        severity: "error",
      });
      return;
    }

    try {
      for (const item of paidItems) {
        const input: PlanPaidItemInput = {
          planId: `${planId}`,
          name: item.name,
          price: item.price,
          description: item.body,
          planQuestionId: `${planId}`,
          status: item.status,
          imageUrl: item.imageUrl || "",
        };

        if (item.id) {
          await updatePlanPaidItem({
            variables: { id: item.id, input },
          });
        } else {
          await createPlanPaidItem({ variables: { input } });
        }
      }
      showNotification({
        message: "Paid items saved successfully.",
        severity: "success",
      });
      refetch();
    } catch (err) {
      showNotification({
        message: "Error saving paid items.",
        severity: "error",
      });
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Grid container sx={{ marginTop: "20px" }}>
      <PageSection lg={12}>
        <Button2
          variant="contained"
          onClick={handleSave}
          sx={{ marginRight: "16px" }}
        >
          Save
        </Button2>
        <IconButton onClick={handleAddMore}>
          <AddIcon />
        </IconButton>
      </PageSection>

      {paidItems.map((paidItem: PaidItem, index: number) => (
        <PageSection lg={12} key={index}>
          <IconButton
            sx={{ marginBottom: "16px" }}
            onClick={() => handleDeletePaidItem(index)}
          >
            <DeleteIcon />
          </IconButton>
          <Grid container spacing={2}>
            <Grid
              item
              lg={8}
              sx={{
                padding: "16px",
                borderRadius: "8px",
                marginBottom: "8px",
                backgroundColor: "white",
              }}
            >
              <TextField
                label="Option Name *"
                fullWidth
                value={paidItem.name}
                error={errors[index] && !paidItem.name}
                helperText={errors[index] && !paidItem.name ? "Required" : ""}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
                sx={{ marginBottom: "10px" }}
              />
              <TextField
                label="Price *"
                fullWidth
                value={paidItem.price}
                type="number"
                error={errors[index] && paidItem.price <= 0}
                helperText={
                  errors[index] && paidItem.price <= 0
                    ? "Price must be positive"
                    : ""
                }
                onChange={(e) =>
                  handleInputChange(index, "price", parseFloat(e.target.value))
                }
                sx={{ marginBottom: "10px" }}
              />
              <TextField
                label="Description *"
                fullWidth
                multiline
                rows={4}
                value={paidItem.body}
                error={errors[index] && !paidItem.body}
                helperText={errors[index] && !paidItem.body ? "Required" : ""}
                onChange={(e) =>
                  handleInputChange(index, "body", e.target.value)
                }
                sx={{ marginBottom: "10px" }}
              />
              <Select
                fullWidth
                value={paidItem.status}
                error={errors[index] && paidItem.status === undefined}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "status",
                    parseInt(`${e.target.value}`)
                  )
                }
              >
                <MenuItem value={1}>Show</MenuItem>
                <MenuItem value={0}>Hide</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              lg={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
                height: "auto",
              }}
            >
              <MediaUploader
                media={paidItem.imageUrl ? paidItem.imageUrl : null}
                allowedTypes={[MediaType.Image]}
                height="200px"
                uploadPath={`plans/templatecard/${paidItem.id}`}
                onChangeFunction={(url: string | null) =>
                  handleImageUpload(index, url)
                }
              />
            </Grid>
          </Grid>
        </PageSection>
      ))}
    </Grid>
  );
}
