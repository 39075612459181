import { gql, useQuery } from "@apollo/client";
import {
  Autocomplete,
  AutocompleteProps,
  Chip,
  TextField,
} from "@mui/material";
import { useMemo } from "react";
import { Location } from "../../types";

type AutocompleteAreaSelectorProps = {
  includeArea: Location[];
  setIncludeArea: (includeArea: Location[]) => void;
} & Partial<AutocompleteProps<any, any, any, any>>;

export default function AutocompleteAreaSelector({
  includeArea,
  setIncludeArea,
  ...autocompleteProps
}: AutocompleteAreaSelectorProps) {
  const { data, error, loading, fetchMore } = useQuery(GET_AREA_LOCATIONS, {
    variables: {
      offset: null,
      limit: null,
      type: "area",
      search: null,
    },
  });

  const areaOptions = useMemo(() => data?.locations.records ?? [], [data]);

  return (
    <Autocomplete
      fullWidth
      multiple
      loading={loading}
      value={includeArea}
      onChange={(_, newValue) => setIncludeArea(newValue as Location[])}
      options={areaOptions}
      getOptionLabel={(option) => option.nameJa}
      disableCloseOnSelect
      renderInput={(params) => <TextField {...params} label="Include Area" />}
      renderTags={(value, getTagProps) =>
        value.map(({ id, nameJa }, index) => (
          <Chip
            label={nameJa}
            {...getTagProps({ index })}
            onClick={() => window.open(`/venues/${id}`, "_blank")}
          />
        ))
      }
      {...autocompleteProps}
    />
  );
}

const GET_AREA_LOCATIONS = gql`
  query LocationsQuery(
    $offset: Int
    $limit: Int
    $type: LocationType
    $search: String
  ) {
    locations(offset: $offset, limit: $limit, type: $type, search: $search) {
      totalRecords
      records {
        id
        type
        nameEn
        nameJa
      }
    }
  }
`;
