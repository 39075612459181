import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import queryString from "query-string";
import { IconButton } from "@mui/material";

import RestoreIcon from "@mui/icons-material/Restore";
import DataTable from "../../../components/DataTable";
import { Template } from "../../../types";
import RestoreTemplateDialog from "./RestoreTemplate";

const TEMPLATES_TRASH_QUERY = gql`
  query TemplatesTrashQuery($offset: Int!, $limit: Int!, $search: String) {
    templatesTrash(
      filter: { offset: $offset, limit: $limit, search: $search }
    ) {
      totalRecords
      records {
        id
        displayName
        overrideDisplayName
        name
        giftmallUrl
      }
    }
  }
`;

export default function ItemsList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const [
    templateToBeRestored,
    setTemplateToBeRestored,
  ] = useState<Template | null>(null);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );

  useEffect(updateUrl, [page, search, navigate]);

  const { loading, error, data, fetchMore } = useQuery(TEMPLATES_TRASH_QUERY, {
    variables: { offset: page * pageSize, limit: pageSize, search: search },
    notifyOnNetworkStatusChange: true,
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "ID", field: "id" },
    { name: "Display name", render: renderTemplateDisplayName },
    { name: "URL", render: renderTemplateShopURL },
    { name: ".", render: renderRestoreTemplate },
  ];
  const rows = data ? data.templatesTrash.records : null;

  const refetch = async () => {
    fetchMore({
      variables: {
        offset: page * pageSize,
        limit: pageSize,
        search: search,
      },
      updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
    });
  };

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.templatesTrash.totalRecords : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
      <RestoreTemplateDialog
        open={templateToBeRestored !== null}
        onClose={() => setTemplateToBeRestored(null)}
        template={templateToBeRestored}
        refetch={refetch}
      />
    </>
  );

  function updateUrl() {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderRestoreTemplate(template: Template) {
    return (
      <IconButton onClick={() => setTemplateToBeRestored(template)}>
        <RestoreIcon />
      </IconButton>
    );
  }

  function renderTemplateDisplayName(template: Template) {
    return (
      <>
        {template.overrideDisplayName ?? template.displayName}
        <br />
        <span style={{ color: "grey" }}>{template.name}</span>
      </>
    );
  }

  function renderTemplateShopURL(template: Template) {
    return (
      <a
        style={{ color: "grey" }}
        href={template.giftmallUrl}
        target="_blank"
        rel="noreferrer"
      >
        {template.giftmallUrl}
      </a>
    );
  }
}
