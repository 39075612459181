import { gql, useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { NotificationStack } from "../../../../components/NotificationStack";
import s from "./OrderItemNotificationStack.module.css";
import { format } from "date-fns";

export function OrderItemNotificationStack() {
  const { data: dataBuyableItems } = useQuery(GET_BOOKINGS_WITH_BUYABLE_ITEMS);

  const bookingsWithBuyableItems = useMemo(() => {
    if (dataBuyableItems === undefined) return [];

    const bookings: {
      id: string;
      reservationDatetime: string;
      productOrders: {
        product: {
          template: {
            overrideDisplayName: string;
          };
        };
      }[];
    }[] = dataBuyableItems.getBookingsWithBuyableItems.bookings;

    return bookings.flatMap((booking) => {
      return booking.productOrders.map((order) => ({
        bookingId: booking.id,
        displayName: order.product.template.overrideDisplayName,
        reservationDatetime: format(new Date(booking.reservationDatetime), 'yyyy-MM-dd HH:mm'),
      }));
    });
  }, [dataBuyableItems]);

  const rows = useMemo(
    () =>
      bookingsWithBuyableItems.map((row) => ({
        link: `/bookings/${row.bookingId}`,
        content: (
          <>
            <Typography className={s.BookingId}>{row.bookingId}</Typography>
            <Typography className={s.ProductName}>{row.displayName}</Typography>
            <Typography>{row.reservationDatetime}</Typography>
          </>
        ),
      })),
    [bookingsWithBuyableItems]
  );

  return (
    <NotificationStack header={"These products can be ordered"} rows={rows} />
  );
}

const GET_BOOKINGS_WITH_BUYABLE_ITEMS = gql`
  query BookingsWithBuyableItemsQuery {
    getBookingsWithBuyableItems {
      bookings {
        id
        reservationDatetime
        productOrders {
          product {
            template {
              overrideDisplayName
            }
          }
        }
      }
    }
  }
`;
