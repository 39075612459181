import { Card, CardContent } from "@mui/material";
import Select from "../../../../components/Select";
import TextField from "../../../../components/TextField";
import { BookingType } from "../../../../types";
import OverwriteTextField from "./OverwriteTextField";

export default function BasicInfo2(props: BasicInfo2Props) {
  return (
    <Card>
      <CardContent>
        <TextField
          label="Cancel Fee"
          startAdornment={"%"}
          value={props.cancelFee.value || ""}
          onChange={(e: any) => {
            props.cancelFee.set(e.target.value);
          }}
        />
        <TextField
          label="Base Price Name"
          value={props.basePriceName.value || ""}
          onChange={(e: any) => {
            props.basePriceName.set(e.target.value);
          }}
        />
        <TextField
          type="number"
          label="Base Price"
          startAdornment={"¥"}
          value={props.basePrice.value}
          onChange={(e: any) => {
            props.basePrice.set(Number(e.target.value));
          }}
        />
        <OverwriteTextField
          label="Score"
          identityValue={0}
          defaultValue={props.score}
          inputProps={{ maxLength: 6 }}
          overwriteValue={props.manualScore.value}
          setOverwriteValue={props.manualScore.set}
          onChange={({
            target: { value },
          }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            props.manualScore.set(Number(value) || 0);
          }}
        />
        <TextField
          multiline
          label="Subtitle"
          value={props.subtitle.value || ""}
          onChange={(e: any) => {
            props.subtitle.set(e.target.value);
          }}
        />
        <TextField
          multiline
          label="Remarks"
          value={props.remarks.value || ""}
          onChange={(e: any) => {
            props.remarks.set(e.target.value);
          }}
        />
        <TextField
          multiline
          label="Cancellation policy description"
          value={props.cancellationPolicyDescription.value || ""}
          onChange={(e: any) => {
            props.cancellationPolicyDescription.set(e.target.value);
          }}
        />
        <TextField
          multiline
          label="Payment method description"
          value={props.paymentMethodDescription.value || ""}
          onChange={(e: any) => {
            props.paymentMethodDescription.set(e.target.value);
          }}
        />
        <Select
          style={{ marginTop: "10px" }}
          label="Booking type"
          value={props.bookingType.value}
          options={Object.entries(BookingType).map(([key, value]) => {
            return {
              id: key,
              name: value,
            };
          })}
          onChange={(option: BookingType) => {
            props.bookingType.set(option);
          }}
        />
      </CardContent>
    </Card>
  );
}

interface BasicInfo2Props {
  basePrice: { value?: number; set: (value?: number) => void };
  cancelFee: { value?: number; set: (value?: number) => void };
  basePriceName: { value?: string; set: (value: string) => void };
  score?: number;
  manualScore: {
    value: number | null;
    set: React.Dispatch<React.SetStateAction<number | null>>;
  };
  subtitle: { value: string; set: (value: string) => void };
  remarks: { value: string; set: (value: string) => void };
  bookingType: { value: BookingType; set: (value: BookingType) => void };
  cancellationPolicyDescription: {
    value: string;
    set: (value: string) => void;
  };
  paymentMethodDescription: { value: string; set: (value: string) => void };
}
