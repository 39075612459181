import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Paper, IconButton, LinearProgress } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { EditStatus, Pagination } from "../../../../types";
import BookingEditHistoryCard from "./BookingEditHistoryCard";

type Props = {
  bookingId: String;
};

export type BookingEditHistoryType = {
  id: string;
  bookingId: string;
  backofficeUserId: string;
  fieldName: string;
  editStatus: EditStatus;
  oldValue: string;
  newValue: string;
  createdAt: string;
  backofficeUser: {
    username: string;
    id: string;
  };
};

const BookingEditHistories = (props: Props) => {
  const [pagination, setPagination] = React.useState<Pagination>({
    limit: 10,
    offset: 0,
  });

  const { loading, error, data, refetch } = useQuery(
    GET_BOOKING_EDIT_HISTORIES,
    {
      variables: { id: props.bookingId, ...pagination },
    }
  );

  const records: BookingEditHistoryType[] = React.useMemo(() => {
    return data?.editHistories?.records ?? [];
  }, [data]);

  const previousTotalRecords = React.useRef<number | undefined>(undefined);

  const totalRecords: number = React.useMemo(() => {
    const result =
      data?.editHistories.totalRecords ?? previousTotalRecords.current ?? 0;
    previousTotalRecords.current = result;
    return result;
  }, [data]);

  const currentPage = React.useMemo(
    () => Math.floor(pagination.offset / pagination.limit),
    [pagination]
  );
  const lastPage = React.useMemo(
    () => Math.max(0, Math.ceil(totalRecords / pagination.limit) - 1),
    [totalRecords, pagination.limit]
  );

  const goToPage = React.useCallback(
    (page: number) => {
      setPagination((pagination) => {
        return {
          ...pagination,
          offset: Math.min(lastPage, Math.max(0, page)) * pagination.limit,
        };
      });
    },
    [lastPage, setPagination]
  );

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}
      >
        <Paper
          style={{
            display: "flex",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            alignItems: "center",
          }}
        >
          <IconButton disabled={currentPage === 0} onClick={() => goToPage(0)}>
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
          <IconButton
            disabled={currentPage === 0}
            onClick={() => goToPage(currentPage - 1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>

          <span style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
            {Math.min(pagination.offset + 1, totalRecords)} -{" "}
            {Math.min(pagination.offset + pagination.limit, totalRecords)} /{" "}
            {totalRecords}
          </span>

          <IconButton
            disabled={currentPage >= lastPage}
            onClick={() => goToPage(currentPage + 1)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            disabled={currentPage >= lastPage}
            onClick={() => goToPage(lastPage)}
          >
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
        </Paper>
        {loading && (
          <LinearProgress style={{ width: "100%", position: "absolute" }} />
        )}
      </div>
      <div>
        {records.map((item: BookingEditHistoryType) => {
          return <BookingEditHistoryCard key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
};

export default BookingEditHistories;

export const GET_BOOKING_EDIT_HISTORIES = gql`
  query BookingEditHistoriesQuery($id: ID!, $offset: Int!, $limit: Int!) {
    editHistories(bookingId: $id, offset: $offset, limit: $limit) {
      records {
        id
        bookingId
        backofficeUserId
        fieldName
        editStatus
        oldValue
        newValue
        createdAt
        backofficeUser {
          username
          id
        }
      }
      totalRecords
    }
  }
`;
