import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import TextField from "../../../../components/TextField";

export default function RedirectUrl(props: RedirectUrlProps) {
  return (
    <>
      <Card>
        <CardHeader title={"Redirect Url"} />
        <Divider />
        <CardContent>
          <TextField
            label="Redirect Url"
            value={props.redirectUrl}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setRedirectUrl(event.target.value);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
}

interface RedirectUrlProps {
  redirectUrl: string;
  setRedirectUrl: Function;
}
