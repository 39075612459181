import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slider from "@mui/material/Slider";

import Notification, {
  NotificationData,
} from "../../../../../components/Notification";
import { ActivityReservationType } from "../types";
import { PaymentStatus } from "../../../../../types";
import { LinearProgress } from "@mui/material";
import { GET_BOOKING } from "../..";

const VenuePaymentRateMarks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export default function CancelReservationDialog(props: CancelReservationProps) {
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );

  return (
    <>
      <CancelDialog />
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function CancelDialog() {
    const [cancelVenueReservation, { loading }] = useMutation(
      CANCEL_VENUE_RESERVATION
    );
    const [venuePaymentRate, setVenuePaymentRate] = useState(0);

    const reservationItems = props.reservation.items;
    const commissionRate =
      props.reservation?.activity?.contract?.commissionRate;
    const rateForVenue = (100 - commissionRate) / 100;

    const expectedForVenue =
      reservationItems.reduce(
        (total, item) => total + item.contractedPrice * item.quantity,
        0
      ) * rateForVenue;

    const actualAmountForVenue = (expectedForVenue * venuePaymentRate) / 100;

    return (
      <Dialog open={props.open} onClose={props.closeDialog}>
        <DialogTitle>Cancel Venue Reservation</DialogTitle>
        <DialogContent>
          <p>
            Do we still need to pay the venue?
            <br />
            <br />
            Contract: {props.reservation.cancellationPolicyDescription || ""}
            <Slider
              disabled={loading}
              defaultValue={venuePaymentRate}
              aria-labelledby="discrete-slider-small-steps"
              step={5}
              marks={VenuePaymentRateMarks}
              min={0}
              max={100}
              valueLabelDisplay="on"
              onChange={(_e: unknown, newValue) =>
                setVenuePaymentRate(newValue as number)
              }
            />
            <br />
            {`${actualAmountForVenue}JPY of ${expectedForVenue}JPY will be paid to the venue`}
          </p>
          <br />
          This action will:
          <ul>
            <li>
              For instant bookings, cancel reservation at external booking
              management services (like Ebica)
            </li>
            <li>Change reservation status to CANCELLED</li>
            <li>
              Send reservation cancelled email to venue <br />
              (if reservation datetime has not already passed)
            </li>
            <li>
              Booking will be updated in the <b>next</b> step
            </li>
          </ul>
          Are you sure you want to cancel the reservation?
          <br />
        </DialogContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button onClick={props.closeDialog}>No</Button>
            <Button color="primary" onClick={cancelReservation}>
              Yes
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );

    function cancelReservation() {
      cancelVenueReservation({
        variables: {
          input: {
            activityReservationId: props.reservation.id,
            venuePaymentRate,
          },
        },
        refetchQueries: [
          "VenueReservationsListQuery",
          "BookingActivityReservationsQuery",
          GET_BOOKING,
        ],
      })
        .then(() => {
          setNotification({
            message: `Venue reservation was successfully canceled`,
            severity: "success",
          });
          props.closeDialog();
        })
        .catch((reason) => {
          setNotification({
            message: `Cancelling venue reservation failed! ${reason}`,
            severity: "error",
          });
        });
    }
  }
}

const CANCEL_VENUE_RESERVATION = gql`
  mutation cancelVenueReservation($input: CancelVenueReservationInput!) {
    cancelVenueReservation(input: $input) {
      success
    }
  }
`;

interface CancelReservationProps {
  reservation: ActivityReservationType;
  open: boolean;
  closeDialog: () => void;
  paymentMethod: string | undefined;
  paymentStatus: PaymentStatus;
}
